import colors from "@/theme/colors"
import styled from "@emotion/styled"
import { Box, Typography } from "@mui/material"
import { sendGTMEvent } from "@next/third-parties/google"
import Image from "next/image"
import Link from "next/link"

const WidgetWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

interface ContactWidgetProps {
  address: string
  phone: string
}

export const ContactWidget = ({ address, phone }: ContactWidgetProps) => {
  return (
    <WidgetWrapper>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Image
          src="/assets/phone.svg"
          alt="phone"
          style={{ alignSelf: "center" }}
          width={14}
          height={14}
        />
        <Typography fontSize={14} pl={1} fontWeight={400}>
          {address}
        </Typography>
      </Box>
      <Link
        onClick={() =>
          sendGTMEvent({
            action: "click",
            event: "agency_phone_call_clicks",
            agency_name: address,
          })
        }
        style={{ color: colors.blue }}
        href={`tel:${phone}`}
      >
        <Typography fontSize={14} pl={0.5} fontWeight={400}>
          {phone}
        </Typography>
      </Link>
    </WidgetWrapper>
  )
}
